import { useMutation } from '@tanstack/react-query';
import fetchJSON from '../../helpers/restApiClient';
import { UPDATENOTE_API_ENDPOINT, API_SECRET_KEY } from '../../constants/config';

const updateNote = async ({ provider_tracking_id, note }) => {
    return fetchJSON(`${UPDATENOTE_API_ENDPOINT}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'X-Secret-Token': API_SECRET_KEY,
        },
        body: { provider_tracking_id, note },
    });
};

const useUpdateNote = () => {
    return useMutation(updateNote);
};

export default useUpdateNote;