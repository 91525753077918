import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const StyledButton = styled(Button)(({ disabled }) => `
    && {
        width: auto;
        padding: 10px 40px; 
        margin: 16px;
        font-size: 16px;
        border-radius: 60px; 
        background-color: ${disabled ? '#ccc' : '#227aeb'}; 
        color: ${disabled ? '#888' : 'white'}; 
        border: none; 
        box-shadow: none; 
        text-transform: capitalize;
    }

    &&:hover {
        background-color: ${disabled ? '#ccc' : '#1967CA'}; 
    }

    @media (min-width: 780px) {
        && {
            padding: 15px 30px;
            font-size: 16px;
            min-width: 240px;
            max-height: 45px;
            margin-left:50px;
        }
    }
`);

const PrimaryButton = ({ type, onClick, children, disabled, startIcon }) => {
    return (
        <StyledButton type={type} variant="contained" onClick={onClick} disabled={disabled} startIcon={startIcon}>
            {children}
        </StyledButton>
    );
};

PrimaryButton.propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    startIcon: PropTypes.node,
};

export default PrimaryButton;
