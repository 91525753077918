import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import routes from './constants/routes';
import LoginContainer from './containers/LoginContainer/LoginContainer';
import SearchUserContainer from './containers/SearchUserContainer/SearchUserContainer';
import UserDetailsContainer from './containers/UserDetailsContainer/UserDetailsContainer';
import CallLogsContainer from './containers/CallLogsContainer/CallLogsContainer';
import PrivateRoutes from './components/PrivateRoutes/PrivateRoutes';
import HeaderNavbar from './components/HeaderNavbar/HeaderNavbar';

const AppRoutes = () => {
    const [phoneNumber, setPhoneNumber] = useState(() => localStorage.getItem('phoneNumber') || '');
    const [admissionNumber, setAdmissionNumber] = useState(() => localStorage.getItem('admissionNumber') || '');

    useEffect(() => {
        localStorage.setItem('phoneNumber', phoneNumber);
        localStorage.setItem('admissionNumber', admissionNumber);
    }, [phoneNumber, admissionNumber]);

    return (
        <div>
            <HeaderNavbar />
            <div>
                <Routes>
                    <Route path={routes.Login} element={<LoginContainer setPhoneNumber={setPhoneNumber} setAdmissionNumber={setAdmissionNumber} />} />
                    <Route element={<PrivateRoutes />}>
                        <Route path={routes.SearchUser} element={<SearchUserContainer />} />
                        <Route path={routes.UserDetails} element={<UserDetailsContainer phoneNumber={phoneNumber} admissionNumber={admissionNumber} />} />
                        <Route path={routes.CallLogs} element={<CallLogsContainer admissionNumber={admissionNumber} />} />
                    </Route>
                    <Route path="*" element={<Navigate to={routes.Login} />} />
                </Routes>
            </div>
        </div>
    );
};

export default AppRoutes;