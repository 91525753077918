import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '../../assets/Icons/homebutton.png';

import './Sidebar.css';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <Link to="/Searchuser">
                <img src={homeIcon} alt="Home" className="home-icon" />
            </Link>
        </div>
    );
};

export default Sidebar;