import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../assets/Icons/ILLogo';
import styles from './HeaderNavbar.module.css';
import useAuth from '../../hooks/useAuth';
import routes from '../../constants/routes';

import './HeaderNavbar.module.css';

const HeaderNavbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        navigate(routes.Login);
    };

    const isUserSearchPage =
        location.pathname.startsWith(routes.SearchUser) ||
        location.pathname.startsWith(routes.UserDetails.replace('/:type/:id', '')) ||
        location.pathname.startsWith(routes.CallLogs.replace('/:id', ''));

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <Logo />
            </div>
            {isUserSearchPage && (
                <div className={styles.logoutContainer}>
                    <button className={styles.logoutButton} onClick={handleLogout}>Logout</button>
                </div>
            )}
        </div>
    );
};

export default HeaderNavbar;