import React from 'react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';

import { logger } from './utils/logger';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import { queryClient, persister } from './store/serverStore'

import Routes from './Routes';

function App() {
  return (

    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logger}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister
        }}
      >
        <ReactQueryDevtools initialIsOpen={false} />
        <Router>
          <Routes />
        </Router>
      </PersistQueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;