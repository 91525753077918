import { useQuery } from '@tanstack/react-query';
import fetchJSON from '../../helpers/restApiClient';
import { FETCH_RECENT_CALL_LOGS_API_ENDPOINT, API_SECRET_KEY } from '../../constants/config';

const fetchRecentCallLogs = async (user_id, callee_id) => {
    const response = await fetchJSON(`${FETCH_RECENT_CALL_LOGS_API_ENDPOINT}?user_id=${user_id}&callee_id=${callee_id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'X-Secret-Token': API_SECRET_KEY,
        }
    });

    const { statusCode, ...logs } = response;
    return Object.values(logs);
};

const useFetchRecentCallLogs = (user_id, callee_id) => {
    return useQuery({
        queryKey: ['recentCallLogs', user_id, callee_id],
        queryFn: () => fetchRecentCallLogs(user_id, callee_id),
        enabled: !!user_id && !!callee_id,
    });
};

export default useFetchRecentCallLogs;