import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const token = Cookies.get('jwtToken');
        return !!token;
    });

    useEffect(() => {
        const token = Cookies.get('jwtToken');
        setIsAuthenticated(!!token);
    }, []);

    const login = (token) => {
        Cookies.set('jwtToken', token, { expires: 7, path: '/' });
        setIsAuthenticated(true);
    };

    const logout = () => {
        Cookies.remove('jwtToken', { path: '/' });
        setIsAuthenticated(false);
    };

    return { isAuthenticated, login, logout };
};

export default useAuth;
