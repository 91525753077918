import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoIosCall } from "react-icons/io";

import { maskPhoneNumber } from '../../utils/maskPhoneNumber';
import { PRODUCT_NAME } from '../../constants/config';
import CustomDialog from '../../widgets/Dialog/Dialog';
import CustomSnackbar from '../../widgets/Snackbar/Snackbar';
import useFetchUserDetails from '../../store/backend/useFetchUserDetails';
import useCall from '../../store/backend/useCall';
import useUpdateNote from '../../store/backend/useUpdateNote';
import Loader from '../../components/Loader/Loader';
import SecondaryButton from '../../widgets/Button/SecondaryButton/SecondaryButton';
import idtype from '../../constants/constants';

import './UserDetailsContainer.css';

const { CRN, UAM } = idtype;

const userDetailsFields = {
    [CRN]: [
        { key: 1, label: "First Name", value: "first_name" },
        { key: 2, label: "Last Name", value: "last_name" },
        { key: 3, label: "Grade", value: "grade" },
    ],
    [UAM]: [
        { key: 1, label: "First Name", value: "first_name" },
        { key: 2, label: "Last Name", value: "last_name" },
        { key: 3, label: "Grade", value: "grade" },
    ]
};

const fetchUser = async (id, type, refetchUserDetails, setUser, setIsLoading) => {
    setIsLoading(true);
    try {
        const response = await refetchUserDetails();
        if (response?.data) {
            setUser(response.data);
        }
    } catch (error) {
        console.error("Error fetching user:", error);
    }
    setIsLoading(false);
};

const handleCall = (setShowConfirmationPopup) => {
    setShowConfirmationPopup(true);
};

const confirmCall = (phoneNumber, admissionNumber, type, id, call, setShowConfirmationPopup, setShowCallPopup, setIsLoadingCall, setProviderTrackingId, setShowSnackbar, setSnackbarMessage) => {
    setIsLoadingCall(true);
    call(
        { callerPhoneNumber: phoneNumber, user_id: admissionNumber, student_id_type: type, student_id: id, productName: PRODUCT_NAME },
        {
            onSuccess: (data) => {
                setProviderTrackingId(data.provider_tracking_id);
                setShowConfirmationPopup(false);
                setShowCallPopup(true);
                setIsLoadingCall(false);
            },
            onError: (error) => {
                setShowConfirmationPopup(false);
                setSnackbarMessage('Call failed');
                setIsLoadingCall(false);
                setShowSnackbar(true);
            }
        }
    );
};

const handleCloseConfirmationPopup = (setShowConfirmationPopup) => {
    setShowConfirmationPopup(false);
};

const handleCloseSnackbar = (setShowSnackbar) => {
    setShowSnackbar(false);
};

const handleCallLogs = (id, navigate) => {
    navigate(`/call-logs/${id}`);
};

const handleCloseCallPopup = (setShowCallPopup) => {
    setShowCallPopup(false);
};

const handleSaveNotes = (providerTrackingId, notes, updateNote, setShowCallPopup) => {
    updateNote(
        { provider_tracking_id: providerTrackingId, note: notes },
        {
            onSuccess: () => {
                console.log('Notes saved successfully');
                setShowCallPopup(false);
            },
            onError: (error) => {
                console.log('Failed to save notes', error);
            }
        }
    );
};

const UserDetailsContainer = ({ phoneNumber, admissionNumber }) => {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCall, setIsLoadingCall] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showCallPopup, setShowCallPopup] = useState(false);
    const [notes, setNotes] = useState('');
    const [providerTrackingId, setProviderTrackingId] = useState('');

    const { refetch: refetchUserDetails } = useFetchUserDetails(id, type);

    const { mutate: call } = useCall();
    const { mutate: updateNote } = useUpdateNote();

    useEffect(() => {
        if (id && type) {
            fetchUser(id, type, refetchUserDetails, setUser, setIsLoading);
        }
    }, [id, type, refetchUserDetails]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="user-details-container">
            <div className="content">
                {user && (
                    <div className="user-details">
                        <div className="details-header">
                            <span className="details-title">Student Details</span>
                            <SecondaryButton onClick={() => handleCallLogs(id, navigate)}>Call Logs</SecondaryButton>
                        </div>
                        <div className="table-container">
                            <table className="user-table">
                                <tbody>
                                    {userDetailsFields[type].map(item => (
                                        <tr key={item.key}>
                                            <td className="field">{item.label}</td>
                                            <td>{user[item.value]}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="field">Phone Number</td>
                                        <td className="phone-cell">
                                            {maskPhoneNumber(user.phone)}
                                            <IoIosCall className="call-icon" size={35} onClick={() => handleCall(setShowConfirmationPopup)} disabled={isLoadingCall} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            <CustomDialog isOpen={showConfirmationPopup} handleOnClose={() => handleCloseConfirmationPopup(setShowConfirmationPopup)}>
                <div className="popup-content">
                    <p>Are you sure you want to continue with the call?</p>
                    <div className="button-container">
                        <SecondaryButton onClick={() => confirmCall(phoneNumber, admissionNumber, type, id, call, setShowConfirmationPopup, setShowCallPopup, setIsLoadingCall, setProviderTrackingId, setShowSnackbar, setSnackbarMessage)} disabled={isLoadingCall}>
                            Yes
                        </SecondaryButton>
                        <SecondaryButton onClick={() => handleCloseConfirmationPopup(setShowConfirmationPopup)}>
                            Cancel
                        </SecondaryButton>
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog isOpen={showCallPopup} handleOnClose={() => handleCloseCallPopup(setShowCallPopup)}>
                <div className="popup-content">
                    <h2>Note</h2>
                    <textarea
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Write your notes here..."
                        rows="10"
                        style={{ width: '100%' }}
                    />
                    <div className="button-container">
                        <SecondaryButton onClick={() => handleSaveNotes(providerTrackingId, notes, updateNote, setShowCallPopup)}>Save Notes</SecondaryButton>
                        <SecondaryButton onClick={() => handleCloseCallPopup(setShowCallPopup)}>Cancel</SecondaryButton>
                    </div>
                </div>
            </CustomDialog>
            <CustomSnackbar
                message={snackbarMessage}
                isOpen={showSnackbar}
                handleClose={() => handleCloseSnackbar(setShowSnackbar)}
            />
        </div >
    );
};

export default UserDetailsContainer;