import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import styled from 'styled-components';

const StyledSnackbarContent = styled(SnackbarContent)`
  && {
    background-color: #28a745; /* Green background color */
    color: #fff; /* White text color */
    font-size: 16px;
    padding: 16px;
    border-radius: 8px;
  }

  @media (min-width: 600px) {
    && {
      border-radius: 12px;
      max-width: 440px;
    }
  }
`;

const CustomSnackbar = ({ message, isOpen, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <StyledSnackbarContent
        message={message}
        aria-describedby="snackbar-message"
      />
    </Snackbar>
  );
};

export default CustomSnackbar;