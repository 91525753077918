import { useMutation } from '@tanstack/react-query';

import fetchJSON from '../../helpers/restApiClient';
import { CALL_API_ENDPOINT, CALL_API_SECRET_TOKEN } from '../../constants/config';

const callApi = async ({ callerPhoneNumber, user_id, student_id_type, student_id, productName }) => {
    return fetchJSON(CALL_API_ENDPOINT, {
        method: 'POST',
        headers: {
            'X-Secret-Token': CALL_API_SECRET_TOKEN,
        },
        body: {
            callerPhoneNumber,
            user_id,
            student_id_type,
            student_id,
            productName,
        },
    });
};

const useCall = () => {
    return useMutation(callApi);
};

export default useCall;
