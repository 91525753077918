import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useFetchRecentCallLogs from '../../store/backend/useFetchRecentCallLogs';
import useUpdateNote from '../../store/backend/useUpdateNote';
import Loader from '../../components/Loader/Loader';
import SecondaryButton from '../../widgets/Button/SecondaryButton/SecondaryButton';
import CustomDialog from '../../widgets/Dialog/Dialog';
import { EDITABLE_TIME_LIMIT_MS } from '../../constants/constants';

import './CallLogsContainer.css';

const handleEditClick = (log, setEditingLog, setEditedNote, setShowEditPopup) => {
    setEditingLog(log);
    setEditedNote(log.note || '');
    setShowEditPopup(true);
};

const handleSaveClick = (updateNote, editingLog, editedNote, setShowEditPopup, setEditingLog, refetch) => {
    updateNote(
        { provider_tracking_id: editingLog.provider_tracking_id, note: editedNote },
        {
            onSuccess: () => {
                setShowEditPopup(false);
                setEditingLog(null);
                refetch();
            },
            onError: (error) => {
                console.log('Failed to save note', error);
            }
        }
    );
};

const handleCloseEditPopup = (setShowEditPopup, setEditingLog) => {
    setShowEditPopup(false);
    setEditingLog(null);
};

const isEditable = (startTime) => {
    if (!startTime) return false;
    const startTimeDate = new Date(startTime);
    const currentTime = new Date();
    return currentTime - startTimeDate <= EDITABLE_TIME_LIMIT_MS;
};

const CallLogsContainer = ({ admissionNumber }) => {
    const { id } = useParams();
    const { data, isLoading, isError, error, refetch } = useFetchRecentCallLogs(admissionNumber, id);
    const { mutate: updateNote } = useUpdateNote();

    const [editingLog, setEditingLog] = useState(null);
    const [editedNote, setEditedNote] = useState('');
    const [showEditPopup, setShowEditPopup] = useState(false);

    useEffect(() => {
        if (id && admissionNumber) {
            refetch();
        }
    }, [id, admissionNumber, refetch]);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div className="error">Error fetching call logs: {error.message}</div>;
    }

    const callLogs = Array.isArray(data) ? data : [];

    return (
        <div className="call-logs-container">
            <div className="content">
                <div className="details-header">
                    <span className="details-title">Call Logs</span>
                    <SecondaryButton onClick={() => window.history.back()}>Back</SecondaryButton>
                </div>
                <div className="table-container">
                    <table className="call-logs-table">
                        <thead>
                            <tr>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Status</th>  
                                <th>Note</th>
                                <th>Recording URL</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {callLogs.length > 0 ? (
                                callLogs.map((log, index) => (
                                    <tr key={index}>
                                        <td>{new Date(log.call_start_time).toLocaleString()}</td>
                                        <td>{log.call_end_time ? new Date(log.call_end_time).toLocaleString() : 'N/A'}</td>
                                        <td>{log.status || 'N/A'}</td>  
                                        <td>{log.note || 'N/A'}</td>
                                        <td>
                                            {log.recording_url ? <a href={log.recording_url} target="_blank" rel="noopener noreferrer">Recording</a> : 'N/A'}
                                        </td>
                                        <td>
                                            <SecondaryButton
                                                onClick={() => handleEditClick(log, setEditingLog, setEditedNote, setShowEditPopup)}
                                                disabled={!isEditable(log.call_start_time)}
                                            >
                                                Edit
                                            </SecondaryButton>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No call logs available</td>  
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <CustomDialog isOpen={showEditPopup} handleOnClose={() => handleCloseEditPopup(setShowEditPopup, setEditingLog)}>
                <div className="popup-content">
                    <h2>Edit Note</h2>
                    <textarea
                        value={editedNote}
                        onChange={(e) => setEditedNote(e.target.value)}
                        placeholder="Write your notes here..."
                        rows="10"
                        style={{ width: '100%' }}
                    />
                    <div className="button-container">
                        <SecondaryButton onClick={() => handleSaveClick(updateNote, editingLog, editedNote, setShowEditPopup, setEditingLog, refetch)}>Save Note</SecondaryButton>
                        <SecondaryButton onClick={() => handleCloseEditPopup(setShowEditPopup, setEditingLog)}>Cancel</SecondaryButton>
                    </div>
                </div>
            </CustomDialog>
        </div>
    );
};

export default CallLogsContainer;