import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINT, API_SECRET_KEY } from '../../constants/config';

const fetchUserDetails = async (student_id, student_id_type) => {
    const response = await fetch(`${API_ENDPOINT}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Secret-Token': API_SECRET_KEY,
        },
        body: JSON.stringify({ student_id_type, student_id }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

const useFetchUserDetails = (student_id, student_id_type) => {
    return useQuery({
        queryKey: ['user', student_id, student_id_type],
        queryFn: () => fetchUserDetails(student_id, student_id_type),
        enabled: false,
    });
};

export default useFetchUserDetails;