import { useMutation } from '@tanstack/react-query';
import useAuth from '../../hooks/useAuth';
import { LOGIN_ENDPOINT, LOGIN_PRODUCT_ID, LOGIN_TENANT_ID, ISD_CODE, REQUEST_PRODUCT_ID, PLATFORM } from '../../constants/config';

const login = async ({ admissionNumber, password }) => {
    const response = await fetch(LOGIN_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'platform': PLATFORM,
            'product-id': LOGIN_PRODUCT_ID,
            'tenant-id': LOGIN_TENANT_ID,
        },
        body: JSON.stringify({
            admission_number: admissionNumber,
            isd_code: ISD_CODE,
            phone: admissionNumber,
            password: password,
            product_id: REQUEST_PRODUCT_ID,
            tenant_id: LOGIN_TENANT_ID,
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.detail || 'Unable to log in';
        throw new Error(errorMessage);
    }

    return response.json();
};

export const useLogin = (onSuccess) => {
    const { login: authLogin } = useAuth();
const mutation = useMutation(login, {
        onSuccess: (data) => {
            authLogin(data.accessToken);
            onSuccess(data);
        },
        onError: (error) => {
            mutation.error = error.message || 'An error occurred';
        }
    });

    return { ...mutation };
};