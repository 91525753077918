export default async function fetchJSON(url, options = {}) {
    return new Promise((resolve, reject) => {
        const newOptions = {
            ...options,
            method: options.method,
            mode: 'cors',
            headers: {
                ...options.headers,
                'Content-Type': 'application/json',
                Authorization: options.authenticationToken
            }
        }
        if (options.body) {
            newOptions.body = JSON.stringify(options.body)
        }
        fetch(url, newOptions)
            .then(
                (response) =>
                    new Promise((innerResolve, innerReject) => {
                        response.json().then(
                            (json) => {
                                innerResolve({
                                    statusCode: response.status,
                                    ...json
                                })
                            },
                            (err) => {
                                innerReject(err)
                            }
                        )
                    })
            )
            .then((jsonResponse) => {
                if (jsonResponse.statusCode >= 200 && jsonResponse.statusCode <= 299) {
                    resolve(jsonResponse)
                } else {
                    reject(jsonResponse)
                }
            })
            .catch((error) => {
                reject(error)
            })
    })
}