import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const StyledButton = styled(Button)(({ disabled }) => ({
    width: 'auto',
    padding: '4px 4px', // Reduced padding
    fontSize: '0.875rem', // Reduced font size
    backgroundColor: disabled ? '#ccc' : '#227aeb',
    color: disabled ? '#888' : 'white',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: disabled ? '#ccc' : '#1967CA',
    },
}));

const SecondaryButton = ({ onClick, children, disabled, startIcon }) => {
    return (
        <StyledButton variant="contained" onClick={onClick} disabled={disabled} startIcon={startIcon}>
            {children}
        </StyledButton>
    );
};

SecondaryButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    startIcon: PropTypes.node,
};

export default SecondaryButton;
