import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import useAuth from '../../hooks/useAuth';

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <div>
      <Sidebar />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;